const logoImages = {
  strawWo: require("../images/pellets-direct-straw-logo-wo.svg"),
  woodWo: require("../images/pellets-direct-wood-logo-wo.svg"),
}

const contactPageImages = {
  afterForm: require("../images/pellets-hand.jpg"),
}

const successPageImages = {
  afterForm: require("../images/pellets-bag.jpg"),
}

const deliveryPageImages = {
  delivery: require("../images/delivery.jpg"),
}

const strawShopImages = {
  delivery: require("../images/delivery.jpg"),
  carousel: {
    items: [
      {
        original: require("../images/straw-pellets-bags.jpg"),
        thumbnail: require("../images/straw-pellets-bags.jpg"),
      },
      {
        original: require("../images/pellets-hand.jpg"),
        thumbnail: require("../images/pellets-hand.jpg"),
      },
      {
        original: require("../images/stable4.jpg"),
        thumbnail: require("../images/stable4.jpg"),
      },
      {
        original: require("../images/pallets.jpg"),
        thumbnail: require("../images/pallets.jpg"),
      },
      {
        original: require("../images/stable3.jpg"),
        thumbnail: require("../images/stable3.jpg"),
      },
    ],
  },
}

const woodShopPageImages = {
  delivery: require("../images/delivery.jpg"),
  carousel: {
    items: [
      {
        original: require("../images/wood-pellets-bags.jpg"),
        thumbnail: require("../images/wood-pellets-bags.jpg"),
      },
      {
        original: require("../images/pellets-hand.jpg"),
        thumbnail: require("../images/pellets-hand.jpg"),
      },
      {
        original: require("../images/stable4.jpg"),
        thumbnail: require("../images/stable4.jpg"),
      },
      {
        original: require("../images/pallets-wood.jpg"),
        thumbnail: require("../images/pallets-wood.jpg"),
      },
      {
        original: require("../images/stable3.jpg"),
        thumbnail: require("../images/stable3.jpg"),
      },
    ],
  },
}

export {
  logoImages,
  contactPageImages,
  deliveryPageImages,
  strawShopImages,
  woodShopPageImages,
  successPageImages,
}
